<template>
    <vue-tel-input
      v-model="phoneNumber"
      mode="international"
      :autoFormat="true"
      :input-options="{ placeholder: 'Enter phone number' }"
      :dropdown-options="{
        showSearchBox: true,
        searchBoxPlaceholder: 'Search country',
        showDialCodeInList: true,
        showFlags: true
      }"
    ></vue-tel-input>
  </template>

  <script>
  import { VueTelInput } from "vue-tel-input";
  import "vue-tel-input/vue-tel-input.css";

  export default {
    name: "PhoneInput",
    components: { VueTelInput },
    data() {
      return {
        phoneNumber: ""
      };
    }
  };
  </script>
