<template>
    <div :class="['block-rounded', 'block', loading ? 'block-mode-loading' : '']" id="Attendance_Container">
        <div class="block-header block-header-default d-flex justify-content-between align-items-center">
            <div>
                <h3 class="block-title m-0">
                    Company Attendance Summary for {{ selectedMonthDisplay }}
                </h3>
            </div>
            <div class="block-options">
                <select class="btn-block-option" id="perPageSelect" v-model="rowsPerPage" @change="changePerPage">
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                </select>
            </div>
        </div>
        <div class="block-content block-content-full">
            <div class="table-responsive">
                <DataTable :value="agents" paginator :rows="rowsPerPage" tableStyle="min-width: 50rem" sortMode="multiple">
                    <!-- Agent Name column: clickable link -->
                    <Column field="name" sortable>
                        <template #header>
                            <div style="text-align: center; width: 100%;">Agent Name</div>
                        </template>
                        <template #body="slotProps">
                            <div style="text-align: center; width: 100%;">
                                <a :href="getUserProfileUrl(slotProps.data.id)" @click.stop>
                                    {{ slotProps.data.name }}
                                </a>
                            </div>
                        </template>
                    </Column>
                    <Column field="present" sortable bodyStyle="text-align: center; vertical-align: middle;">
                        <template #header>
                            <div style="text-align: center; width: 100%;">Present Days</div>
                        </template>
                    </Column>
                    <Column field="late" sortable bodyStyle="text-align: center; vertical-align: middle;">
                        <template #header>
                            <div style="text-align: center; width: 100%;">Late Days</div>
                        </template>
                    </Column>
                    <Column field="late_time" sortable bodyStyle="text-align: center; vertical-align: middle;">
                        <template #header>
                            <div style="text-align: center; width: 100%;">Late Time</div>
                        </template>
                    </Column>
                    <Column field="left_early" sortable bodyStyle="text-align: center; vertical-align: middle;">
                        <template #header>
                            <div style="text-align: center; width: 100%;">Left Early Days</div>
                        </template>
                    </Column>
                    <Column field="overtime" sortable bodyStyle="text-align: center; vertical-align: middle;">
                        <template #header>
                            <div style="text-align: center; width: 100%;">Overtime</div>
                        </template>
                    </Column>
                    <Column field="out_of_zone" sortable bodyStyle="text-align: center; vertical-align: middle;">
                        <template #header>
                            <div style="text-align: center; width: 100%;">Out-of-Zone</div>
                        </template>
                    </Column>
                    <Column>
                        <template #header>
                            <div style="text-align: center; width: 100%;">Calendar</div>
                        </template>
                        <template #body="slotProps">
                            <div style="text-align: center; width: 100%;">
                                <button class="calendar-btn" @click="openCalendar(slotProps.data)">
                                    <i class="calendar-icon pi pi-calendar"></i>
                                </button>
                            </div>
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
    </div>

    <!-- Calendar Modal (Bootstrap Styled) -->
    <div v-if="showCalendar" class="modal fade show" id="calendar_modal" tabindex="-1" style="display: block;" aria-modal="true" role="dialog" @click.self="closeCalendar">
        <div class="modal-dialog modal-dialog-popout" role="document" style="max-width: 50%;">
            <div class="modal-content">
                <div class="block-rounded block-transparent mb-0 block">
                    <div class="block-header block-header-default">
                        <h3 class="block-title">{{ selectedUserName }}</h3>
                        <div class="block-options">
                            <button type="button" class="btn-block-option" @click="closeCalendar" aria-label="Close">
                                <i class="fa fa-fw fa-times"></i>
                            </button>
                        </div>
                    </div>
                    <div class="block-content block-content-full">
                        <!-- The AttendanceCalendar component should now include the status colors -->
                        <AttendanceCalendar :month="selectedMonth" :userId="selectedUserId" />
                    </div>
                    <div class="text-end p-2">
                        <!-- Footer with Add and Close buttons -->
                        <button type="button" class="btn btn-sm btn-primary me-1" @click="openAddRecord">Add</button>
                        <button type="button" class="btn btn-sm btn-alt-secondary" @click="closeCalendar">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Add Record Modal (Bootstrap Styled) -->
    <div v-if="showAddRecordModal" class="modal fade show" id="add_record_modal" tabindex="-1" @click.self="closeAddRecord" aria-modal="true" role="dialog" style="display: block;">
        <div class="modal-dialog modal-dialog-popout" role="document">
            <div class="modal-content">
                <div class="block-rounded block-transparent mb-0 block">
                    <div class="block-header block-header-default">
                        <h3 class="block-title">Add Attendance Record</h3>
                        <div class="block-options">
                            <button type="button" class="btn-block-option" @click="closeAddRecord" aria-label="Close">
                                <i class="fa fa-fw fa-times"></i>
                            </button>
                        </div>
                    </div>
                    <div class="block-content block-content-full">
                        <form @submit.prevent="submitAddRecord">
                            <!-- Hidden inputs for user_id and partner_id -->
                            <input type="hidden" v-model="newRecord.user_id" />
                            <div class="mb-3">
                                <label class="form-label" for="attendance-date">Date</label>
                                <input type="date" id="attendance-date" class="form-control" v-model="newRecord.date" required />
                            </div>
                            <div class="mb-3">
                                <label class="form-label" for="attendance-status">Status</label>
                                <select id="attendance-status" class="form-select" v-model="newRecord.status" required>
                                    <option value="present">Present</option>
                                    <option value="partial">Partial</option>
                                    <option value="late">Late</option>
                                    <option value="left_early">Left Early</option>
                                    <option value="absent">Absent</option>
                                    <option value="out_of_zone">Out Of Zone</option>
                                </select>
                            </div>
                            <div class="mb-3">
                                <label class="form-label" for="attendance-overtime">Overtime (minutes)</label>
                                <input type="number" id="attendance-overtime" class="form-control" v-model.number="newRecord.overtime" min="0" required />
                            </div>
                            <div class="mb-3">
                                <label class="form-label" for="attendance-late-time">Late Time (minutes)</label>
                                <input type="number" id="attendance-late-time" class="form-control" v-model.number="newRecord.late_time" min="0" required />
                            </div>
                            <div class="text-end">
                                <button type="button" class="btn btn-sm btn-alt-secondary me-1" @click="closeAddRecord">Close</button>
                                <button type="submit" class="btn btn-sm btn-primary">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import axios from 'axios'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import AttendanceCalendar from './AttendanceCalendar.vue' // Adjust path as needed

const agents = ref([])
const selectedMonth = ref('')
const selectedMonthDisplay = ref('')
const rowsPerPage = ref(10)
const loading = ref(true)

// Calendar modal states
const showCalendar = ref(false)
const selectedUserId = ref(null)
const selectedUserName = ref('')

// Add Record modal state and model
const showAddRecordModal = ref(false)
const newRecord = ref({
    user_id: '',
    partner_id: '',
    date: '',
    status: 'present',
    overtime: 0,
    late_time: 0
})

// Fetch agents data
function fetchAttendanceTable(month) {
    loading.value = true
    selectedMonth.value = month
    const [year, mon] = month.split('-')
    const dateObj = new Date(year, parseInt(mon, 10) - 1)
    selectedMonthDisplay.value = dateObj.toLocaleString('default', { month: 'long', year: 'numeric' })

    axios.get('/resources/company-attendance', { params: { month } })
        .then(response => {
            agents.value = response.data
        })
        .catch(error => {
            console.error('Error fetching attendance data:', error)
        })
        .finally(() => {
            loading.value = false
        })
}

document.addEventListener('monthChanged', (event) => {
    const month = event.detail.selectedMonth
    fetchAttendanceTable(month)
})

onMounted(() => {
    const today = new Date()
    const monthStr = today.getFullYear() + '-' + String(today.getMonth() + 1).padStart(2, '0')
    fetchAttendanceTable(monthStr)
})

// Build the profile URL
const getUserProfileUrl = (userId) => `/profile/${userId}`

// Calendar modal functions
function openCalendar(agentData) {
    selectedUserId.value = agentData.id
    selectedUserName.value = agentData.name
    showCalendar.value = true
}

function closeCalendar() {
    showCalendar.value = false
}

// Add Record modal functions
function openAddRecord() {
    // Pre-fill new record with selected user details.
    newRecord.value.user_id = selectedUserId.value
    newRecord.value.partner_id = selectedUserId.value  // As requested: same as user_id
    newRecord.value.date = ''
    newRecord.value.status = 'present'
    newRecord.value.overtime = 0
    newRecord.value.late_time = 0
    showAddRecordModal.value = true
}

function closeAddRecord() {
    showAddRecordModal.value = false
}

function submitAddRecord() {
    // Convert minutes to seconds before posting.
    const recordToPost = {
        ...newRecord.value,
        overtime: newRecord.value.overtime * 60,
        late_time: newRecord.value.late_time * 60
    };

    axios.post('/resources/attendance-record', recordToPost)
        .then(response => {
            fetchAttendanceTable(selectedMonth.value);
            closeAddRecord();
            closeCalendar();
        })
        .catch(error => {
            console.error('Error adding attendance record:', error);
        });
}

// Handle changes in rows per page
const changePerPage = () => {
    fetchAttendanceTable(selectedMonth.value)
}
</script>

<style scoped>
.agents-attendance {
    margin: 20px;
}

h2 {
    margin-bottom: 1rem;
}

button.p-button {
    border: none;
    background: transparent;
    cursor: pointer;
}

.dialog-header {
    width: 100%;
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
}

/* Bootstrap modal overrides */
.modal {
    display: block;
    background: rgba(0, 0, 0, 0.5);
}
.modal-dialog {
    margin: 1.75rem auto;
}
.btn-block-option {
    background: transparent;
    border: none;
    cursor: pointer;
}

/* Calendar button styling */
.calendar-btn {
    background: transparent;
    border: none;
    padding: 0.5rem;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    color: #007bff;
    font-size: 1rem;
    transition: color 0.2s ease;
}
.calendar-btn:hover {
    color: #0056b3;
}
.calendar-icon {
    font-size: 1.2rem;
}
</style>
