// Import necessary dependencies
import './bootstrap'; // Import your existing bootstrap.js or similar setup
import { formatNumberShort, formatInputNumber, validateBudget } from './utils.js';
import { createApp } from 'vue'; // Import Vue.js
//import 'chartjs-adapter-date-fns';
import '../css/app.css';
// Vue.js setup (if you're using Vue components)
import router from './router'; // Import the router
import PrimeVue from 'primevue/config'; // Import PrimeVue configuration
import 'primeicons/primeicons.css';
import ConfirmationService from 'primevue/confirmationservice'
import DialogService from 'primevue/dialogservice'
import ToastService from 'primevue/toastservice';
import Aura from '@primevue/themes/aura';
import Toast from './components/common/Toast.vue'; // Import your Vue component
import SingleAvatarChip from './components/common/SingleAvatarChip.vue'; // Import your Vue component
import AvatarGroup from './components/common/AvatarGroup.vue'; // Import your Vue component
import LatestFeedbacks from './components/LatestFeedbacks.vue'; // Import your Vue component
import ResponsiveGalleria from './components/ResponsiveGalleria.vue'; // Import your Vue component
import ShareButton from './components/properties/ShareButton.vue'; // Import your Vue component
import StatusButton from './components/properties/StatusButton.vue'; // Import your Vue component
import UserRoles from './components/partner/UserRoles.vue'; // Import your Vue component
import TeamsTable from './components/partner/TeamsTable.vue'; // Import your Vue component
import PartnerDashboard from './components/charts/PartnerDashboard.vue';
import DashboardCards from './components/charts/DashboardCards.vue';
import PartnerStatistics from './components/charts/PartnerStatistics.vue';
import PartnerEaring from './components/charts/PartnerEaring.vue';
import AttendanceTimeline from './components/AttendanceTimeline.vue';
import AttendanceCalendar from './components/AttendanceCalendar.vue';
import AttendanceTable from './components/AttendanceTable.vue';
import phone from './components/phone.vue';
import PhoneInput from "./components/PhoneInput.vue";
import phonenumbers from "./components/PhoneNumbers.vue";
import VCalendar from 'v-calendar';
import 'v-calendar/style.css';
import 'primeicons/primeicons.css';


const app = createApp({});
app.use(router);
app.use(PrimeVue, {
    theme: {
        preset: Aura,
        options: {
            prefix: 'p',
            darkModeSelector: 'light',
            cssLayer: false
        }
    }
});
app.use(VCalendar, {})
app.use(ConfirmationService);
app.use(ToastService);
app.use(DialogService);
app.component("phone-input", PhoneInput);
app.component("phone-numbers", phonenumbers);
app.component('toast', Toast);
app.component('latest-feedbacks-table', LatestFeedbacks);
app.component('single-avatar-chip', SingleAvatarChip);
app.component('avatar-group', AvatarGroup);
app.component('responsive-galleria', ResponsiveGalleria);
app.component('property-share-button', ShareButton);
app.component('property-status-button', StatusButton);
app.component('user-roles-table', UserRoles);
app.component('teams-table', TeamsTable);
app.component('partner-dashboard', PartnerDashboard);
app.component('dashboard-cards', DashboardCards);
app.component('partner-statistics', PartnerStatistics);
app.component('partner-earing', PartnerEaring);
app.component('attendance-timeline', AttendanceTimeline);
app.component('attendance-calendar', AttendanceCalendar);
app.component('attendance-table', AttendanceTable);
app.component('phone', phone);



window.formatNumberShort = formatNumberShort;
window.formatInputNumber = formatInputNumber;
window.validateBudget = validateBudget;


app.mount('#main-container');
document.addEventListener('DOMContentLoaded', function() {
    const fetchWithLocation = async (url, options = {}) => {
        try {
            const position = await new Promise((resolve, reject) => {
                navigator.geolocation.getCurrentPosition(resolve, reject);
            });
            const params = new URLSearchParams(options.params || {});
            params.append('lat', position.coords.latitude);
            params.append('lon', position.coords.longitude);

            const newUrl = url + '?' + params.toString();
            return fetch(newUrl, options);
        } catch (error) {
            console.error('Error getting location:', error);
        }
    };
});
