<template>
    <div v-for="(events, date) in groupedEvents" :key="date">
        <Timeline :value="events" align="top">
            <template #marker="slotProps">
                <span
                    class="flex w-10 h-10 items-center justify-center text-white rounded-full z-10 shadow-sm cursor-pointer"
                    :style="{ backgroundColor: slotProps.item.color }"
                    @click="openGoogleMaps(slotProps.item.latitude, slotProps.item.longitude)">
                    <i :class="slotProps.item.icon"></i>
                </span>
            </template>
            <template #opposite="slotProps">
                <small class="text-surface-500 dark:text-surface-400">{{ slotProps.item.time }}</small>
            </template>
            <template #content="slotProps">
                <div>{{ slotProps.item.status }}</div>
                <small class="text-surface-500 dark:text-surface-400">
                    <template v-if="slotProps.item.createdById">
                        <a :href="`/profile/${slotProps.item.createdById}`"
                            class="text-blue-600 font-semibold hover:underline">
                            {{ slotProps.item.by }}
                        </a>
                    </template>
                    <template v-else>
                        {{ slotProps.item.by }}
                    </template>
                </small>
            </template>
        </Timeline>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'AttendanceTimeline',
    props: {
        userId: {
            type: [String, Number],
            required: true
        },
        date: { // Expecting a date string (could be a specific day or a month, as per your backend)
            type: String,
            required: true
        }
    },
    data() {
        return {
            events: []
        };
    },
    computed: {
        groupedEvents() {
            const groups = {};
            this.events.forEach(event => {
                if (!groups[event.date]) {
                    groups[event.date] = [];
                }
                groups[event.date].push(event);
            });
            return groups;
        }
    },
    methods: {
        formatTime(date) {
            const d = new Date(date);
            return d.toLocaleTimeString('en-US', {
                hour: 'numeric',
                minute: '2-digit',
                hour12: true
            }).replace(' ', '\u00A0');
        },
        getStatusIcon(status) {
            switch (status) {
                case 'InZone':
                    return 'pi pi-map-marker';
                case 'OutOfZone':
                    return 'pi pi-exclamation-circle';
                case 'CheckedIn':
                    return 'pi pi-sign-in';
                case 'CheckedOut':
                    return 'pi pi-sign-out';
                default:
                    return 'pi pi-question-circle';
            }
        },
        getStatusColor(status) {
            switch (status) {
                case 'InZone':
                    return '#4CAF50'; // Green
                case 'OutOfZone':
                    return '#F44336'; // Red
                case 'CheckedIn':
                    return '#2196F3'; // Blue
                case 'CheckedOut':
                    return '#FFC107'; // Yellow
                default:
                    return '#9E9E9E'; // Grey
            }
        },
        openGoogleMaps(lat, long) {
            const url = `https://www.google.com/maps?q=${lat},${long}`;
            window.open(url, '_blank');
        }
    },
    mounted() {
        axios
            .get('/resources/get-attendance', {
                params: {
                    userId: this.userId,
                    date: this.date
                }
            })
            .then(response => {
                this.events = response.data.map(item => ({
                    status: item.status,
                    by: item.created_by,
                    createdById: item.created_by_id || null,
                    time: this.formatTime(item.created_at.$date || item.created_at),
                    date: new Date(item.created_at.$date || item.created_at).toLocaleDateString(),
                    latitude: item.latitude,
                    longitude: item.longitude,
                    icon: this.getStatusIcon(item.status),
                    color: this.getStatusColor(item.status)
                }));
            })
            .catch(error => {
                console.error('Error fetching attendance data:', error);
            });
    }
};
</script>

<style scoped>
.card {
    padding: 1rem;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.cursor-pointer {
    cursor: pointer;
}
</style>
