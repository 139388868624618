<script setup>
import { ref, computed, onMounted } from 'vue'
import axios from 'axios'
import 'v-calendar/dist/style.css'
import { Calendar as VCalendar } from 'v-calendar'
import Dialog from 'primevue/dialog'
import AttendanceTimeline from './AttendanceTimeline.vue' // Adjust path if needed

// Define props for month and userId (passed from parent)
const props = defineProps({
    month: { type: String, required: true },
    userId: { type: [String, Number], required: true }
})

// Initialize focusedDate based on the provided month (default to day "01")
const focusedDate = ref(new Date(props.month + '-01'))
console.log("Initial focusedDate:", focusedDate.value)

// Helper: Convert a Date to a "YYYY-MM" string
function getMonthString(date) {
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`
}

// Use provided month as initial current month
const currentMonth = ref(props.month)
console.log("Initial currentMonth:", currentMonth.value)

// Ref to hold fetched attendance summaries
const attendances = ref([])

// Loading flag
const loading = ref(false)

// Mapping of attendance status to colors
const statusColorMap = {
    present: 'green',
    late: 'orange',
    left_early: 'red',
    absent: 'gray',
    out_of_zone: 'blue',
    error: 'pink',
    partial: 'purple'
}

// Helper: Format seconds into "Xh Ym Zs"
function formatDuration(seconds) {
    const h = Math.floor(seconds / 3600)
    const m = Math.floor((seconds % 3600) / 60)
    const s = seconds % 60
    return `${h}h ${m}m ${s}s`
}

// Fetch attendance data for the currentMonth and given userId using async/await
const fetchAttendanceData = async () => {
    console.log("Fetching attendance for month:", currentMonth.value, "userId:", props.userId)
    loading.value = true
    try {
        const response = await axios.get('/resources/attendance-summary', {
            params: { month: currentMonth.value, userId: props.userId }
        })
        attendances.value = response.data
        console.log("Fetched attendance data:", attendances.value)
    } catch (error) {
        console.error("Error fetching attendance data:", error)
    } finally {
        loading.value = false
    }
}

// Compute calendar attributes once the response has been received.
const attributes = computed(() => {
    if (loading.value) {
        return []
    }
    const attrs = []
    // Add attributes from existing attendance records.
    attendances.value.forEach(summary => {
        if (!summary.date) return
        // Base attribute: status highlight and popover label
        attrs.push({
            key: summary.date,
            highlight: {
                fillMode: 'solid',
                color: statusColorMap[summary.status] || 'gray'
            },
            popover: {
                label: `${summary.status.toUpperCase()}${summary.remarks ? ' - ' + summary.remarks : ''}`
            },
            dates: new Date(summary.date),
            order: 0
        })
        if (summary.late_time > 0) {
            attrs.push({
                key: summary.date + '-late',
                dot: { color: 'orange' },
                dates: new Date(summary.date),
                order: 1,
                popover: { label: `Late: ${formatDuration(summary.late_time)}` }
            })
        }
        if (summary.overtime > 0) {
            attrs.push({
                key: summary.date + '-overtime',
                dot: { color: 'purple' },
                dates: new Date(summary.date),
                order: 2,
                popover: { label: `Overtime: ${formatDuration(summary.overtime)}` }
            })
        }
        if (summary.created_by && summary.created_by !== 'System') {
            const userDisplay = summary.created_by.name;
            const updatedTime = new Date(summary.updated_at).toLocaleString();
            attrs.push({
                key: summary.date + '-manual',
                dot: { color: 'red' },
                popover: {
                    label: `${userDisplay} - ${updatedTime}`
                },
                dates: new Date(summary.date),
                order: 3
            });
        }
    })

    // Determine the date range: start from the 1st of the month up to today (if current month) or the last day.
    const startDate = new Date(currentMonth.value + '-01')
    const today = new Date()
    let endDate
    if (getMonthString(today) === currentMonth.value) {
        endDate = today
    } else {
        // Get the last day of the month.
        endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0)
    }

    // Loop over each day in the range. For days without a record, mark them as absent.
    for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
        const dateString = d.toISOString().split('T')[0]
        const recordExists = attendances.value.some(summary => summary.date === dateString)
        if (!recordExists) {
            attrs.push({
                key: dateString + '-absent',
                highlight: {
                    fillMode: 'solid',
                    color: statusColorMap.absent
                },
                popover: {
                    label: 'ABSENT'
                },
                dates: new Date(dateString),
                order: 0
            })
        }
    }
    return attrs
})

// Fetch data on component mount
onMounted(() => {
    fetchAttendanceData()
})

// Handle the "did-move" event from VCalendar
function handleDidMove(pages) {
    console.log("did-move event fired, pages:", pages)
    if (pages && pages.length > 0) {
        const newMonth = pages[0].id
        console.log("New month detected from pages[0].id:", newMonth)
        if (newMonth !== currentMonth.value) {
            currentMonth.value = newMonth
            fetchAttendanceData()
        }
    }
}

// Helper function to format a date locally (YYYY-MM-DD)
function formatLocalDate(date) {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    return `${year}-${month}-${day}`
}

// Modal management for timeline
const showTimelineModal = ref(false)
const selectedDate = ref(null)

// When a day is clicked, set the selected date and open the timeline modal
function handleDayClick(day, event) {
    console.log("Day clicked:", day)
    selectedDate.value = formatLocalDate(day.date)
    console.log("Selected date set to:", selectedDate.value)
    showTimelineModal.value = true
}
</script>

<template>
    <div class="calendar-container">
        <div class="legend d-flex flex-wrap gap-2 mb-3">
            <span class="fs-xs fw-semibold d-inline-block py-1 px-3 rounded-pill bg-green-500 text-white">Present</span>
            <span class="fs-xs fw-semibold d-inline-block py-1 px-3 rounded-pill bg-orange-500 text-white">Late</span>
            <span class="fs-xs fw-semibold d-inline-block py-1 px-3 rounded-pill bg-red-500 text-white">Left
                Early</span>
            <span class="fs-xs fw-semibold d-inline-block py-1 px-3 rounded-pill bg-gray-500 text-white">Absent</span>
            <span
                class="fs-xs fw-semibold d-inline-block py-1 px-3 rounded-pill bg-blue-500 text-white">Out-of-Zone</span>
            <span class="fs-xs fw-semibold d-inline-block py-1 px-3 rounded-pill bg-pink-500 text-white">Error</span>
            <span
                class="fs-xs fw-semibold d-inline-block py-1 px-3 rounded-pill bg-purple-500 text-white">Partial</span>
        </div>
        <VCalendar expanded v-model="focusedDate" :attributes="attributes" @did-move="handleDidMove"
            @dayclick="handleDayClick" />
        <!-- Modal for AttendanceTimeline -->
        <Dialog v-model:visible="showTimelineModal" modal dismissableMask :style="{ width: '70vw' }">
            <template #header>
                <div class="dialog-header">Timeline for {{ selectedDate }}</div>
            </template>
            <!-- Pass both userId and date to the timeline -->
            <AttendanceTimeline :userId="props.userId" :date="selectedDate" />
        </Dialog>
    </div>
</template>

<style scoped>
.calendar-container {
    margin: 20px;
}

.dialog-header {
    width: 100%;
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
}
.bg-green-500 {
  background-color: #22c55e; /* Tailwind's green-500 */
}

.bg-orange-500 {
  background-color: #f97316; /* Tailwind's orange-500 */
}

.bg-red-500 {
  background-color: #ef4444; /* Tailwind's red-500 */
}

.bg-gray-500 {
  background-color: #6b7280; /* Tailwind's gray-500 */
}

.bg-blue-500 {
  background-color: #3b82f6; /* Tailwind's blue-500 */
}

.bg-pink-500 {
  background-color: #ec4899; /* Tailwind's pink-500 */
}

.bg-purple-500 {
  background-color: #8b5cf6; /* Tailwind's purple-500 */
}

.text-white {
  color: #ffffff;
}
</style>
