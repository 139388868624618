<template>
    <div class="col phoneNumbersContainer">
        <!-- Loop through each phone input -->
        <div v-for="(phone, index) in phoneInputs" :key="index" class="mb-2 w-100 input-group phone-input-group">
            <!-- The phone input itself -->
            <phone-input class="form-control" v-model="phoneInputs[index].sim_number"
                @country-changed="updateCountry(index, $event)" @blur="handleBlur" />
            <!-- Add/Remove button inside the same input group -->
            <button v-if="index === 0" type="button" class="btn btn-success" @click="addPhoneNumber"
                :disabled="phoneInputs.length >= maxPhoneNumbers">
                <i class="fa fa-fw fa-plus"></i>
            </button>
            <button v-else type="button" class="btn btn-danger" @click="removePhoneNumber(index)">
                <i class="fa fa-fw fa-minus"></i>
            </button>
        </div>

        <!-- Hidden inputs for form submission -->
        <div v-for="(phone, index) in phoneInputs" :key="'hidden-' + index">
            <input type="hidden" :name="'sim_number[]'" :value="phone.sim_number" />
            <input type="hidden" :name="'country_code[]'" :value="getCountryCode(phone)" />
        </div>

        <!-- Loader indicator -->
        <div v-if="isProcessing" id="clientNumLoader">Processing...</div>
    </div>
</template>

<script>
import PhoneInput from "./PhoneInput.vue";
import axios from "axios";
import Swal from "sweetalert2";

export default {
    name: "PhoneNumbers",
    components: { PhoneInput },
    props: {
        defaultPhone: {
            type: [String, Array],
            default: ""
        }
    },
    data() {
        return {
            maxPhoneNumbers: 4,
            isProcessing: false,
            phoneInputs: this.initializePhones()
        };
    },
    methods: {
        initializePhones() {
            if (Array.isArray(this.defaultPhone) && this.defaultPhone.length > 0) {
                return this.defaultPhone.map(phone => ({
                    sim_number: phone,
                    country_code: ""
                }));
            } else if (typeof this.defaultPhone === "string" && this.defaultPhone.trim() !== "") {
                return [{
                    sim_number: this.defaultPhone,
                    country_code: ""
                }];
            }
            return [{ sim_number: "", country_code: "" }];
        },
        addPhoneNumber() {
            if (this.phoneInputs.length < this.maxPhoneNumbers) {
                this.phoneInputs.push({ sim_number: "", country_code: "" });
            } else {
                Swal.fire("Error", "You cannot add more than 4 phone numbers.", "error");
            }
        },
        removePhoneNumber(index) {
            this.phoneInputs.splice(index, 1);
        },
        updateCountry(index, countryData) {
            const iso2 = typeof countryData === "object" && countryData.iso2 ? countryData.iso2 : countryData;
            this.phoneInputs[index].country_code = iso2;
        },
        handleBlur() {
            if (this.phoneInputs.every(phone => phone.sim_number.trim() !== "")) {
                this.checkOwnership();
            }
        },
        getCountryCode(phone) {
            return typeof phone.country_code === "object" && phone.country_code.iso2
                ? phone.country_code.iso2
                : phone.country_code;
        },
        collectFormData() {
            return this.phoneInputs.map(phone => ({
                sim_number: phone.sim_number.trim().replace(/\s+/g, ""),
                country_code: typeof phone.country_code === "object" && phone.country_code.iso2
                    ? phone.country_code.iso2
                    : phone.country_code,
            }));
        },
        checkOwnership() {
            if (this.isProcessing) return;
            const formData = this.collectFormData();
            if (!formData.length || formData.some(data => data.sim_number === "")) {
                return;
            }
            this.isProcessing = true;
            axios
                .post("/clients/check-client-number", { sim_data: formData })
                .then(response => {
                    if (response.data.message) {
                        Swal.fire("Success", response.data.message, "success");
                        const submitBtnElem = document.getElementById("form_submit_client_button");
                        if (submitBtnElem) submitBtnElem.disabled = false;
                    }
                    if (response.data.client) {
                        const client = response.data.client;
                        // Update client details in the DOM
                        const clientIdElem = document.getElementById("clientId");
                        if (clientIdElem) clientIdElem.value = client._id;
                        const clientNameElem = document.getElementById("clientName");
                        if (clientNameElem) clientNameElem.value = client.name;
                        const clientEmailElem = document.getElementById("clientEmail");
                        if (clientEmailElem) clientEmailElem.value = client.email;
                        const clientAddressElem = document.getElementById("clientAddress");
                        if (clientAddressElem) clientAddressElem.value = client.address;

                        // Parse returned phone numbers and country codes
                        let simNumbers = client.sim_number;
                        let countryCodes = client.country_code;
                        if (!Array.isArray(simNumbers)) {
                            try {
                                simNumbers = JSON.parse(simNumbers);
                            } catch (e) {
                                simNumbers = [simNumbers];
                            }
                        }
                        if (!Array.isArray(countryCodes)) {
                            try {
                                countryCodes = JSON.parse(countryCodes);
                            } catch (e) {
                                countryCodes = [countryCodes];
                            }
                        }

                        // Merge manual entries (with priority) and response entries uniquely
                        const unionEntries = {};
                        // Add manual entries (normalize numbers by removing spaces)
                        this.phoneInputs.forEach(phone => {
                            const num = phone.sim_number.trim().replace(/\s+/g, '');
                            if (num) {
                                unionEntries[num] = phone.country_code;
                            }
                        });
                        // Add response entries if not already present
                        simNumbers.forEach((num, index) => {
                            const normalized = num.trim().replace(/\s+/g, '');
                            if (normalized && !unionEntries.hasOwnProperty(normalized)) {
                                unionEntries[normalized] = (countryCodes && countryCodes[index]) ? countryCodes[index] : "";
                            }
                        });

                        let unionNumbers = Object.keys(unionEntries);
                        let unionCountries = unionNumbers.map(n => unionEntries[n]);

                        if (unionNumbers.length > this.maxPhoneNumbers) {
                            Swal.fire(
                                "Warning",
                                "The total phone numbers exceed the maximum allowed. Only the first " + this.maxPhoneNumbers + " numbers were added.",
                                "warning"
                            );
                            unionNumbers = unionNumbers.slice(0, this.maxPhoneNumbers);
                            unionCountries = unionCountries.slice(0, this.maxPhoneNumbers);
                        }
                        this.phoneInputs = unionNumbers.map((num, index) => ({
                            sim_number: num,
                            country_code: unionCountries[index]
                        }));

                    }
                    if (response.data.error) {
                        Swal.fire("Error", response.data.error, "error");
                        const submitBtnElem = document.getElementById("form_submit_client_button");
                        if (submitBtnElem) submitBtnElem.disabled = true;
                    }
                })
                .catch(error => {
                    console.error("AJAX Error:", error);
                    let errorMessage = "An error occurred while checking phone number ownership.";
                    if (error.response && error.response.data && error.response.data.error) {
                        errorMessage = error.response.data.error;
                    }
                    Swal.fire("Error", errorMessage + error, "error");
                    const submitBtnElem = document.getElementById("form_submit_client_button");
                    if (submitBtnElem) submitBtnElem.disabled = true;
                })
                .finally(() => {
                    this.isProcessing = false;
                });
        },
    },
};
</script>

<style scoped>
.phoneNumbersContainer {
    width: 100%;
    margin: 0;
    padding: 0;
}

.phone-input-group {
    margin: 0;
    padding: 0;
    width: 100%;
}

.input-group {
    width: 100%;
}

form-control {
    margin: 0;
    padding: 0;
}
</style>
